<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import CloseIcon from '@/Components/Icons/CloseIcon.vue';
import BaseButton from '@/Components/Panel/BaseButton.vue';

withDefaults(defineProps<{
    isOpen: boolean,
    title?: string | null,
}>(), {
    title: null,
});

const emit = defineEmits<{
    close: []
}>();

function close() {
    emit('close');
}
</script>

<template>
    <TransitionRoot
        :show="isOpen"
        as="template"
    >
        <Dialog
            as="div"
            class="relative z-10"
            @close="close"
        >
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black/40 backdrop-blur-xs" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="w-full h-full flex items-end sm:items-center justify-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="flex flex-col max-h-[80svh] w-full max-w-xl transform overflow-hidden align-middle shadow-xl transition-all rounded-lg bg-white m-4"
                        >
                            <div
                                v-if="title"
                                class="shrink-0 flex justify-between items-center border-b rounded-t-lg border-gray-200 bg-white px-4 py-3"
                            >
                                <h3
                                    v-if="title"
                                    class="text-base font-semibold leading-6 text-gray-900"
                                >
                                    {{ title }}
                                </h3>
                                <BaseButton
                                    class="-mr-1.5"
                                    type="secondary"
                                    ghost
                                    @click="close"
                                >
                                    <template #icon>
                                        <CloseIcon />
                                    </template>
                                </BaseButton>
                            </div>
                            <div class="shrink [&>div]:p-5 overflow-auto">
                                <slot />
                            </div>
                            <div
                                v-if="$slots.footer"
                                class="px-4 py-3 sm:px-6 border-t border-gray-200"
                            >
                                <div class="flex justify-end w-full space-x-2">
                                    <slot name="footer" />
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
